import classNames from 'classnames';
import IconDrag from 'components/Icons/IconDrag';
import React from 'react';

function DragHandleComponent(
  props: React.HTMLProps<HTMLDivElement>,
  ref: React.Ref<HTMLElement>
) {
  const { className, ...rest } = props;

  return (
    <span
      ref={ref}
      {...rest}
      className={classNames(
        'flex h-6 w-6 flex-shrink-0 cursor-grab items-center justify-center rounded-md',
        className
      )}
    >
      <IconDrag className="flex-shrink-0" />
    </span>
  );
}

export const DragHandle = React.forwardRef(DragHandleComponent);
