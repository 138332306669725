import { ColorFamily } from '@graphql-types@';
import IconPlus from 'components/Icons/IconPlus';
import { useAtomCallback } from 'jotai/utils';
import React, { useCallback } from 'react';
import { DraggableType } from 'types/drag-and-drop';
import { BOTTOM, DRAFT } from './constants';
import { DropIndicator } from './DropIndicator';
import { useTodosDraggable } from './todos-dnd';
import {
  activeCategoryIdAtom,
  activeTodoIdAtom,
  todoDraftPositionAtom,
} from './todosAtoms';
import { inputFocusAtom } from './useFocusControl';
import { useIsDraggingOver } from './utils';

export interface TodoAddButtonProps {
  colorFamily: ColorFamily;
  categoryId: string;
}

export const TodoAddButton = React.memo(TodoAddButtonComponent);
function TodoAddButtonComponent({
  categoryId,
  colorFamily,
}: TodoAddButtonProps) {
  const { setNodeRef } = useTodosDraggable<HTMLDivElement>(
    'draft-' + categoryId,
    DraggableType.TODO_PLACEHOLDER
  );

  const isDraggingOver = useIsDraggingOver('draft-' + categoryId, [
    DraggableType.TODO,
    DraggableType.EVENT,
  ]);

  const setActive = useAtomCallback(
    useCallback(
      (_, set) => {
        // we know this draft is always at the bottom of the list
        set(todoDraftPositionAtom, BOTTOM);
        set(activeCategoryIdAtom, categoryId);
        set(activeTodoIdAtom, null);
        set(inputFocusAtom, { id: DRAFT });
      },
      [categoryId]
    )
  );

  return (
    <div ref={setNodeRef} className="relative flex">
      <div className="flex h-6 w-6 shrink-0" />
      <DropIndicator
        className="w-[80%]"
        direction={isDraggingOver ? 'above' : 'none'}
        colorFamily={colorFamily}
      />

      <button
        className="group flex flex-shrink-0 grow items-center gap-0.5 rounded-md text-s font-medium opacity-50 transition-opacity hover:opacity-100"
        onClick={setActive}
      >
        <span className="flex h-6 w-6 items-center justify-center">
          <IconPlus className="h-2.5" />
        </span>
        Todo
      </button>
    </div>
  );
}
