import { mergeProps, useUpdateEffect } from '@react-aria/utils';
import classNames from 'classnames';
import IconCheck from 'components/Icons/IconCheck';
import { useHotkeyContext } from 'contexts/hotkey';
import { AnimatePresence, motion } from 'framer-motion';
import useClickOutside from 'hooks/useClickOutside';
import useHotkey from 'hooks/useHotkey';
import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from 'react';
import { Placement, useLayer, useMousePositionAsTrigger } from 'react-laag';
import ResizeObserver from 'resize-observer-polyfill';
import { cleanDropdownItems, PropsOf } from './utils';

export interface DropdownItemSeparator {
  type: 'separator';
  hidden?: boolean;
}

export interface DropdownItemTitle {
  type: 'title';
  value: string;
  hidden?: boolean;
}

export interface DropdownItemOption {
  type: 'option';
  value: string;
  notification?: React.ReactElement;
  description?: string;
  disabled?: boolean;
  hidden?: boolean;
  selected?: boolean;
  onSelect?: (actions: DropdownActions) => void;
  icon?: JSX.Element;
  variant?: 'red' | 'active';
}

export interface DropdownItemCustom {
  type: 'custom';
  content: React.ReactElement;
  disabled?: boolean;
  hidden?: boolean;
  onSelect?: (actions: DropdownActions) => void;
}

export type DropdownItem =
  | DropdownItemCustom
  | DropdownItemOption
  | DropdownItemTitle
  | DropdownItemSeparator;

export interface DropdownActions {
  setOpen: (value: boolean) => void;
  setHighlightedIndex: (value: number, scrollIntoView: boolean) => void;
}

export interface DropdownProps {
  items: DropdownItem[] | (() => DropdownItem[]);
  className?: string;
  menuClassName?: string;
  fullWidth?: boolean;
  offset?: number;
  children: React.ReactElement;
  placement?: Placement;
  onClose?: () => void;
  enableRightClick?: boolean;
  contextMenuAtClickPosition?: boolean;
  disabled?: boolean;
}

function DropdownComponent(
  {
    className,
    menuClassName,
    fullWidth,
    offset = 4,
    items: unfilteredItems,
    children,
    placement = 'bottom-start',
    onClose,
    enableRightClick,
    contextMenuAtClickPosition = true,
    disabled = false,
  }: DropdownProps,
  ref: React.Ref<HTMLDivElement>
) {
  const [open, setOpen] = useState(false);

  const selectedElement = useRef<HTMLLIElement | null>(null);
  const { setScope, setPreviousScope } = useHotkeyContext();
  const [highlightedIndex, _setHighlightedIndex] = useState(-1);

  const items = useMemo(
    () =>
      open
        ? cleanDropdownItems(
            typeof unfilteredItems === 'function'
              ? unfilteredItems()
              : unfilteredItems
          )
        : [],
    [open, unfilteredItems]
  );
  const showMenu = items.length > 0;

  const {
    resetMousePosition, // reset the mouse-position to `null`, essentially closing the menu
    handleMouseEvent, // event-handler we will use below
    trigger, // information regarding positioning we can provide to `useLayer`
  } = useMousePositionAsTrigger({ enabled: enableRightClick });

  const setHighlightedIndex = useCallback(
    (value: number, scrollIntoView = false) => {
      _setHighlightedIndex(value);

      if (!scrollIntoView) return;
      selectedElement.current?.scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
      });
    },
    []
  );

  const close = useCallback(() => {
    _setHighlightedIndex(-1);
    resetMousePosition();
    setOpen(false);
    onClose?.();
  }, [onClose, resetMousePosition]);

  const { renderLayer, triggerProps, triggerBounds, layerProps, layerSide } =
    useLayer({
      isOpen: showMenu && !disabled,
      auto: true,
      snap: true,
      placement,
      possiblePlacements: [
        'top-start',
        'top-end',
        'bottom-start',
        'bottom-end',
        placement,
      ],
      triggerOffset: offset,
      containerOffset: 12,
      onOutsideClick: close,
      onParentClose: close,
      ResizeObserver,
      trigger:
        enableRightClick && contextMenuAtClickPosition ? trigger : undefined,
    });

  const actions: DropdownActions = useMemo(() => {
    return {
      setOpen,
      setHighlightedIndex,
    };
  }, [setHighlightedIndex]);

  const closeMenuOnRightClickOutside = useCallback(
    (e) => {
      if (e.button === 2 && enableRightClick) {
        close();
      }
    },
    [close, enableRightClick]
  );

  useClickOutside(ref, closeMenuOnRightClickOutside);

  const submitItem = useCallback(
    (item?: DropdownItemOption | undefined) => {
      close();
      item?.onSelect?.(actions);
    },
    [actions, close]
  );

  const getTriggerProps = useCallback(
    (childrenProps: PropsOf<'button'>) => {
      const props: PropsOf<'button'> = {};
      if (!enableRightClick) {
        props.onClick = () => {
          if (open) {
            close();
          } else {
            setOpen(true);
          }
        };
      } else {
        props.onContextMenu = (e) => {
          e.preventDefault();
          handleMouseEvent(e);
          if (open) {
            close();
          } else {
            setOpen(true);
          }
        };
      }
      return mergeProps(childrenProps, props);
    },
    [enableRightClick, handleMouseEvent, close, open]
  );

  const getItemProps = useCallback(
    ({
      disabled,
      index,
      item,
    }: {
      disabled: DropdownItemOption['disabled'];
      index: number;
      item: DropdownItem;
    }) => {
      const props: PropsOf<'li'> = {
        onMouseDown: (event) => event.preventDefault(),
      };

      if (item.type !== 'option' || disabled) return props;

      props.onMouseEnter = () => setHighlightedIndex(index);
      props.onMouseLeave = () => setHighlightedIndex(-1);
      props.onClick = (event) => {
        event.stopPropagation();
        submitItem(item);
      };

      if (index === highlightedIndex) {
        props.ref = selectedElement;
      }

      return props;
    },
    [highlightedIndex, setHighlightedIndex, submitItem]
  );

  const moveHighlightedIndex = useCallback(
    (direction: 'up' | 'down', scrollIntoView = false) => {
      const navigableItems = items.filter(
        (item) => item.type === 'option' && !item.disabled
      );
      const relativeIndex =
        highlightedIndex === -1
          ? highlightedIndex
          : navigableItems.indexOf(items[Math.max(highlightedIndex, 0)]);

      const nextRelativeIndex =
        direction === 'up' ? relativeIndex - 1 : relativeIndex + 1;

      const relativeIndexMin = Math.max(nextRelativeIndex, 0);
      const allowedRelativeIndex = Math.min(
        relativeIndexMin,
        navigableItems.length - 1
      );

      const nextIndex = items.indexOf(navigableItems[allowedRelativeIndex]);

      setHighlightedIndex(nextIndex, scrollIntoView);
    },
    [highlightedIndex, items, setHighlightedIndex]
  );

  useUpdateEffect(() => {
    if (showMenu) {
      setScope('dropdown');
    } else {
      setPreviousScope();
    }
  }, [showMenu, setScope, setPreviousScope]);

  useEffect(() => {
    return () => {
      // Handles edge case where menu was open and then it's unmounted from another place.
      if (showMenu) return setPreviousScope();
    };
  }, [setPreviousScope, showMenu]);

  useHotkey(
    'escape',
    { scope: 'dropdown', enabled: showMenu, enabledWithinInput: true },
    close
  );

  useHotkey(
    'enter',
    {
      scope: 'dropdown',
      enabled: showMenu,
      enabledWithinInput: true,
    },
    () => {
      const highlightedItem = items[Math.max(highlightedIndex, 0)];
      const highlightedOption =
        highlightedItem?.type === 'option' ? highlightedItem : undefined;
      submitItem(highlightedOption);
    }
  );

  useHotkey(
    'up, down',
    {
      scope: 'dropdown',
      enabled: showMenu,
      enabledWithinInput: true,
    },
    (event, hotkey) => {
      if (hotkey.key !== 'up' && hotkey.key !== 'down') return;
      event.preventDefault();

      moveHighlightedIndex(hotkey.key, true);
      setOpen(true);
    }
  );

  return (
    <div ref={ref} className={className}>
      {React.cloneElement(children, {
        ...getTriggerProps(mergeProps(children.props, triggerProps)),
      })}
      {/* renderLayer should only run under the same condition as isOpen provided
      to useLayer
      https://github.com/everweij/react-laag/issues/52#issuecomment-746146753 //
      https://github.com/everweij/react-laag#quick-start */}
      {showMenu &&
        renderLayer(
          <AnimatePresence>
            {showMenu && (
              <motion.ul
                {...layerProps}
                className={classNames(
                  'bg-dropdown text-primary group z-100 overflow-y-auto rounded-lg text-s font-medium shadow-feintXl',
                  menuClassName
                )}
                style={{
                  width: fullWidth ? triggerBounds?.width : undefined,
                  ...layerProps.style,
                }}
                initial={{
                  opacity: 0,
                  scale: 0.99,
                  y: layerSide === 'top' ? 8 : -8,
                }}
                animate={{ opacity: 1, y: 0, scale: 1 }}
                exit={{
                  opacity: 0,
                  scale: 0.99,
                  y: layerSide === 'top' ? 8 : -8,
                }}
                transition={{
                  duration: 0.08,
                }}
              >
                {items.map((item, index) => {
                  if (item.type === 'separator') {
                    return (
                      <li
                        key={index}
                        className="mx-3 -mt-1 mb-2.5 flex pt-1.5"
                        {...getItemProps({
                          disabled: true,
                          item,
                          index,
                        })}
                      >
                        <span className="flex h-px w-full bg-gray-100 dark:bg-gray-800" />
                      </li>
                    );
                  }

                  if (item.type === 'title') {
                    return (
                      <li
                        key={index}
                        className="flex px-3.5 pt-3 pb-2"
                        {...getItemProps({
                          disabled: true,
                          item,
                          index,
                        })}
                      >
                        <p className="text-[11px] font-black uppercase leading-snug tracking-wider text-gray-400">
                          {item.value}
                        </p>
                      </li>
                    );
                  }

                  if (item.type === 'custom') {
                    return (
                      <li
                        key={index}
                        {...getItemProps({
                          disabled: true,
                          item,
                          index,
                        })}
                      >
                        {item.content}
                      </li>
                    );
                  }

                  return (
                    <li
                      key={index}
                      className="group -mt-2 flex w-full cursor-pointer p-1 first:mt-0"
                      aria-label={item.value}
                      aria-current={index === highlightedIndex}
                      {...getItemProps({
                        item,
                        index,
                        disabled: item.disabled,
                      })}
                    >
                      <div
                        className={classNames(
                          'flex w-full items-center truncate rounded-md px-2.5 py-2',
                          {
                            'cursor-not-allowed opacity-30': item.disabled,
                            'bg-gray-100 dark:bg-gray-700':
                              index === highlightedIndex && !item.variant,
                            'bg-red-50 dark:bg-red-400/10':
                              index === highlightedIndex &&
                              item.variant === 'red',
                            'text-red-500 dark:text-red-400':
                              item.variant === 'red',
                            'font-semibold': item.variant === 'active',
                          }
                        )}
                      >
                        {item.icon && (
                          <div
                            className={classNames(
                              'mr-2 flex h-4 w-4 flex-shrink-0 items-center justify-center transition-colors',
                              {
                                'text-gray-600 dark:text-gray-300':
                                  index === highlightedIndex && !item.variant,
                                'text-gray-500 dark:text-gray-400':
                                  index !== highlightedIndex && !item.variant,
                                'text-red-400': item.variant === 'red',
                                'mt-0.5 self-start': item.description,
                              }
                            )}
                          >
                            {item.icon}
                          </div>
                        )}
                        <div className="flex w-full flex-col pr-3">
                          <p
                            className={classNames('truncate', {
                              'font-semibold': item.selected,
                            })}
                          >
                            {item.value}
                          </p>
                          <div className="text-secondary truncate text-[11px] leading-4">
                            {item.description}
                          </div>
                        </div>

                        {item.notification && item.notification}

                        {item.selected && (
                          <div className="ml-auto flex pl-0.5">
                            <IconCheck className="h-4 w-4 text-green-400" />
                          </div>
                        )}
                      </div>
                    </li>
                  );
                })}
              </motion.ul>
            )}
          </AnimatePresence>
        )}
    </div>
  );
}

const Dropdown = React.memo(React.forwardRef(DropdownComponent));

export default Dropdown;
