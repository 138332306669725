import classNames from 'classnames';
import { Todos } from 'components/Todos';
import { AnimatePresence, motion } from 'framer-motion';
import useFeatureFlag from 'hooks/useFeatureFlag';
import useHotkey from 'hooks/useHotkey';
import { useUpdateModal } from 'hooks/useModal';
import usePrevious from 'hooks/usePrevious';
import { useAtom } from 'jotai';
import React, { useCallback, useEffect, useRef } from 'react';
import { ModalType } from 'types/modal';
import {
  CONTACTPANEL_WIDTH,
  SIDEPANEL_WIDTH_MAX,
  SIDEPANEL_WIDTH_MIN,
} from './utils';
import { useAvatarsProximityTracking } from './EagerEventsLoader';
import { atomWithStorage } from 'jotai/utils';

export const sidebarWidthAtom = atomWithStorage(
  'amie-sidepanel-width',
  SIDEPANEL_WIDTH_MIN + 35
);

interface Props {
  isSidepanelExpanded: boolean;
  toggleSidepanel: (value?: boolean) => void;
  isSidepanelResizing: boolean;
  setIsSidepanelResizing: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function SidePanel({
  isSidepanelExpanded,
  toggleSidepanel,
  isSidepanelResizing,
  setIsSidepanelResizing,
}: Props): JSX.Element {
  const { closeModal } = useUpdateModal();

  const [expandedWidth, setExpandedWidth] = useAtom(sidebarWidthAtom);
  const initialClientX = useRef(0);
  const wasExpanded = usePrevious(isSidepanelExpanded);
  const didExpandChange = wasExpanded !== isSidepanelExpanded;
  const onResizeStart = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      initialClientX.current = event.clientX;
      setIsSidepanelResizing(true);
      document.body.classList.add('cursor-ew-resize');
    },
    [setIsSidepanelResizing]
  );

  const onResize = useCallback(
    (mouseEvent: MouseEvent) => {
      const diff = mouseEvent.clientX - initialClientX.current;

      setExpandedWidth((width) => {
        const newWidth = width + diff;
        const minMax = Math.floor(
          Math.max(Math.min(newWidth, SIDEPANEL_WIDTH_MAX), SIDEPANEL_WIDTH_MIN)
        );

        if (
          newWidth >= SIDEPANEL_WIDTH_MIN &&
          newWidth <= SIDEPANEL_WIDTH_MAX
        ) {
          initialClientX.current = mouseEvent.clientX;
        }

        if (
          mouseEvent.clientX <
          (SIDEPANEL_WIDTH_MIN + CONTACTPANEL_WIDTH) / 2
        ) {
          toggleSidepanel(false);
        }

        if (
          mouseEvent.clientX >
          (SIDEPANEL_WIDTH_MIN + CONTACTPANEL_WIDTH) / 2
        ) {
          toggleSidepanel(true);
        }

        return minMax;
      });
    },
    [setExpandedWidth, toggleSidepanel]
  );

  const onResizeEnd = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      setIsSidepanelResizing(false);
      document.body.classList.remove('cursor-ew-resize');
    },
    [setIsSidepanelResizing]
  );

  const resetWidth = useCallback(() => {
    setExpandedWidth(SIDEPANEL_WIDTH_MIN);
    document.body.classList.remove('cursor-ew-resize');
  }, [setExpandedWidth]);

  useEffect(() => {
    if (isSidepanelResizing) {
      document.addEventListener('mousemove', onResize);
      document.addEventListener('mouseup', onResizeEnd);
    }

    return () => {
      document.removeEventListener('mousemove', onResize);
      document.removeEventListener('mouseup', onResizeEnd);
    };
  }, [isSidepanelResizing, onResize, onResizeEnd]);

  useHotkey(
    'l, cmd+\\',
    { scope: 'global', enabled: !isSidepanelResizing },
    () => toggleSidepanel()
  );

  const { startTracking, stopTracking } = useAvatarsProximityTracking();

  return (
    <div
      onClick={() => closeModal(ModalType.Event)}
      onMouseEnter={startTracking}
      onMouseLeave={stopTracking}
      role="presentation"
      style={{
        width: isSidepanelExpanded ? expandedWidth : 0,
        willChange: 'width',
        transform: 'translate3d(0, 0, 0)',
      }}
      className={classNames(
        'bg-background relative z-100 flex h-full flex-shrink-0',
        {
          'border-separator border-r': isSidepanelExpanded,
          'transition-width duration-100':
            !isSidepanelResizing || didExpandChange,
        }
      )}
    >
      <div className="flex w-full flex-col overflow-x-hidden">
        <AnimatePresence>
          {isSidepanelExpanded && (
            <motion.div
              initial={{ x: -8, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -8, opacity: 0 }}
              className="flex w-full flex-shrink-0 flex-col"
              transition={{ duration: 0.1, ease: 'easeInOut' }}
              style={{ width: expandedWidth }}
              key="sidePanel"
            >
              <Todos className="py-2 pr-3.5 pl-0.5" />
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <div
        role="presentation"
        onMouseDown={onResizeStart}
        onDoubleClick={resetWidth}
        className={classNames(
          'group absolute top-0 right-0 z-100 flex h-full w-1 cursor-ew-resize items-center justify-start',
          {
            'pointer-events-none opacity-0': !isSidepanelExpanded,
            'opacity-100 transition-opacity duration-100': isSidepanelExpanded,
          }
        )}
      >
        <div className="absolute -right-1.5 flex h-16 items-center justify-center pl-1 pr-3">
          <span
            className={classNames(
              'flex h-16 w-1 rounded-full transition-colors group-hover:bg-gray-300 dark:group-hover:bg-gray-600',
              {
                'bg-gray-300 dark:bg-gray-600': isSidepanelResizing,
                'bg-gray-200 dark:bg-gray-700': !isSidepanelResizing,
              }
            )}
          />
        </div>
      </div>
    </div>
  );
}
