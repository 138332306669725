import classNames from 'classnames';
import IconClose from 'components/Icons/IconClose';
import IconPlus from 'components/Icons/IconPlus';
import { useAtomCallback, useAtomValue } from 'jotai/utils';
import Button from 'joy/Button';
import Tooltip from 'joy/Tooltip';
import React, { useCallback } from 'react';
import {
  ARIA_LABEL_CLOSE_ARCHIVED_LIST,
  ARIA_LABEL_TODO_ADD_CATEGORY,
} from 'utils/constants';
import CategoryOptions from './CategoryOptions';
import { SECTION_HEADER_ID } from './constants';
import { usePreferences, useUpdatePreference } from 'hooks/usePreferences';
import { PreferenceName } from 'types/preference';
import {
  showArchivedListsAtom,
  draftCategoryIdAtom,
  todosAtom,
} from './todosAtoms';
import { v4 as uuid } from 'uuid';

const buttonClassName =
  'flex w-6 h-6 items-center justify-center text-icons hover:bg-gray-150 rounded-lg dark:hover:bg-gray-700 hover:text-gray-600 dark:hover:text-gray-200';

export default React.memo(function TodoSectionHeader() {
  const showArchivedLists = useAtomValue(showArchivedListsAtom);
  const sectionTitle = showArchivedLists ? 'Archived' : 'Todos';
  const updatePreference = useUpdatePreference();
  const preferences = usePreferences();
  const showDoneTodos = preferences.showDoneTodos;

  const onAddCategory = useAtomCallback(
    useCallback((_, set) => {
      set(draftCategoryIdAtom, uuid());
    }, [])
  );

  const onToggleDoneTodos = useCallback(() => {
    updatePreference(PreferenceName.ShowDoneTodos, !showDoneTodos);
  }, [showDoneTodos, updatePreference]);

  const onToggleArchivedLists = useAtomCallback(
    useCallback((get, set) => {
      /* 
        reset the optimisticTodosAtom value to the 
        default - that of the parent serverTodosAtom;
        otherwise optimisticTodosAtom and serverTodosAtom
        become out of sync when moving categories between archived and active lists
        Docs:  https://jotai.org/docs/utils/atom-with-default
        */
      set(todosAtom, { categories: [], todos: [] });
      set(showArchivedListsAtom, !get(showArchivedListsAtom));
    }, [])
  );

  return (
    <div className="bg-background border-separator fixed z-30 flex h-15 w-full flex-shrink-0 items-center justify-between border-b pl-8 pr-4 font-semibold">
      {sectionTitle}
      {showArchivedLists ? (
        <CloseArchivedListButton
          onToggleArchivedLists={onToggleArchivedLists}
        />
      ) : (
        <div className={classNames('flex items-center justify-between gap-2')}>
          {/* prevent adding new lists when viewing archived categories */}
          <TodoSectionAddCategory onAddCategory={onAddCategory} />
          <CategoryOptions
            menuId={SECTION_HEADER_ID}
            tooltipTextOverwrite="Options"
            onToggleDoneTodos={onToggleDoneTodos}
            toggleArchivedLists={onToggleArchivedLists}
          />
        </div>
      )}
    </div>
  );
});

function TodoSectionAddCategory({
  onAddCategory,
}: {
  onAddCategory: () => void;
}) {
  return (
    <Tooltip content="New list">
      <Button
        className={buttonClassName}
        ariaLabel={ARIA_LABEL_TODO_ADD_CATEGORY}
        onClick={onAddCategory}
      >
        <IconPlus className="h-3 w-3" />
      </Button>
    </Tooltip>
  );
}

function CloseArchivedListButton({
  onToggleArchivedLists,
}: {
  onToggleArchivedLists: () => void;
}) {
  return (
    <Button
      className={buttonClassName}
      ariaLabel={ARIA_LABEL_CLOSE_ARCHIVED_LIST}
      onClick={onToggleArchivedLists}
    >
      <IconClose className="h-4 w-4" />
    </Button>
  );
}
